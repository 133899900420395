.ReactTable {
    flex: 2;
    height: 100%;
    width: 100%;
    color: #546982;
    border: none;
}

.ReactTable .rt-thead.-header {
    box-shadow: none;
    background-color: #EEF2F6;
    color: #455a87;
}

.ReactTable.DashboardTable .rt-thead.-header {
    box-shadow: none;
    background-color: transparent;
    color: inherit;
    margin-bottom: 0.6rem;
}

.ReactTable.DashboardTable .rt-tfoot {
    box-shadow: none;
    background-color: transparent;
    color: inherit;
}

.ReactTable.DashboardTable .rt-thead.-header .rt-tr .rt-th:first-child {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.ReactTable.DashboardTable .rt-thead.-header .rt-tr .rt-th:last-child {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

.ReactTable.DashboardTable .rt-tbody .rt-td, .ReactTable.DashboardTable .rt-tfoot  .rt-td {
    padding: 3px 5px;
    text-align: center;
    border-right: 0;
}
.ReactTable.DashboardTable .rt-th {
    white-space: pre-wrap;
}

.ReactTable .rt-tbody .rt-tr-group {
    border-bottom: none;
    color: #405063;
}

.rt-th {
    text-align: left;
    font-size: 14px;
}

.ReactTable.-striped .rt-tr.-odd {
    background-color: #FFF4DB;
}

.ReactTable.-striped .rt-tr.-even {
    background-color: white;
}

.ReactTable.-striped .rt-table .rt-tr .rt-th .rt-resizable-header {
    font-size: 20px;
}

/*Document table styles*/
ReactTable.DashboardTable.DocumentTable {
    height: auto!important;
}
.ReactTable.DashboardTable .rt-thead .sortableTh .arrowIcon {
    margin-left: 5px;
}
.ReactTable.DashboardTable .rt-thead .-sort-asc .sortableTh .arrowIcon {
    transform: rotate(180deg);
}

.ReactTable.DashboardTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    box-shadow: none;
}
.ReactTable.DashboardTable.DocumentTable {
    justify-content: center;
    align-items: center;
}
.ReactTable.DashboardTable.DocumentTable .rt-tr-group {
    border: 1px solid rgb(241, 243, 244);
    border-top: 0;
}
.ReactTable.DashboardTable.DocumentTable .rt-thead.-header {
    margin-bottom: 0;
}
.ReactTable.DashboardTable.DocumentTable .rt-thead.-header .rt-tr .rt-th:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 5px;
}
.ReactTable.DashboardTable.DocumentTable .rt-thead.-header .rt-tr .rt-th:last-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 5px;
}
.ReactTable.DashboardTable.DocumentTable .rt-tr-group:last-child  {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.ReactTable.DashboardTable.DocumentTable .rt-tbody .rt-td, .ReactTable.DashboardTable .rt-tfoot  .rt-td {
    white-space: inherit;
    text-overflow: inherit;
    padding: 8px 16px;
    text-align: left;
}
.ReactTable .rt-tr-group {
    flex: none;
}
.ReactTable.DashboardTable.DocumentTable .pagination-bottom {
    width: 30%;
}
.ReactTable.DashboardTable.DocumentTable .rt-table {
    margin-bottom: 30px;
}

/*.ReactTable.DashboardTable.DocumentTable .rt-tbody {*/
/*    max-height: 365px;*/
/*    display: block;*/
/*    overflow-y: auto;*/
/*    overflow-x: hidden;*/
/*}*/

/*.ReactTable.DashboardTable.DocumentTable .rt-head {*/
/*    display: table;*/
/*    width: 100%;*/
/*    table-layout: fixed;*/
/*}*/
/**/
