.accordion__button {
     background: none;
     padding: 0;
}

.accordion__button:before {
    display: none;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: none;
    border-right: none;
    transform: rotate(-45deg);
}